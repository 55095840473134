import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        width: '100%',
        padding: '13px 12px 13px 16px',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '1',
        backgroundColor: 'input.bg',
        color: 'input.text',
        borderRadius: '8px',
        _placeholder: {
          color: 'input.placeholder',
        },
        _focus: {},
        _invalid: {
          borderColor: 'input.invalid',
          boxShadow: 'none',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
