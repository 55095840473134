import React, { lazy } from 'react';

import { RoutesEnum } from '@/enum/routes.enum';

const ProfilePage = lazy(() => import('@/pages/Profile'));
const UnsubscribePage = lazy(() => import('@/pages/Unsubscribe'));
const LoginPage = lazy(() => import('@/pages/Login'));
const ForgotPasswordPage = lazy(() => import('@/pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('@/pages/ResetPassword'));
// const ResetCompletePage = lazy(() => import("../pages/ResetCompletePage"));
// const CompleteSignUpPage = lazy(() => import('../pages/CompleteSignUpPage'));
// const Rules = lazy(() => import("../pages/Rules"));
// const HowToPlay = lazy(() => import("../pages/HowToPlay"));
// const AccessFormStep2 = lazy(
//   () => import('../components/LoginLoyoutStep2/AccessFormStep2'),
// );
// const AccessFormStep3 = lazy(
//   () => import('../components/LoginLoyoutStep3/AccessFormStep3'),
// );
const HomePage = lazy(() => import('@/pages/Home'));
const SignupPage = lazy(() => import('@/pages/Signup'));
const SSOEntryPage = lazy(() => import('@/pages/SSOEntry'));

interface IRoutes {
  path: RoutesEnum;
  element: React.ReactNode;
  children?: IRoutes[];
}

export const AppRoutes: IRoutes[] = [
  {
    path: RoutesEnum.PROFILE,
    element: <ProfilePage />,
  },
  {
    path: RoutesEnum.UNSUB,
    element: <UnsubscribePage />,
  },
  {
    path: RoutesEnum.HOME,
    element: <SignupPage />,
  },
  {
    path: RoutesEnum.LOGIN,
    element: <LoginPage />,
  },
  {
    path: RoutesEnum.FORGOT,
    element: <ForgotPasswordPage />,
  },
  {
    path: RoutesEnum.RESET,
    element: <ResetPasswordPage />,
  },
  {
    path: RoutesEnum.SSOENTRY,
    element: <SSOEntryPage />,
  },
  // {
  //   path: RoutesEnum.COMPLETE,
  //   element: <ResetCompletePage />,
  // },
  // {
  //   path: RoutesEnum.SIGNUP,
  //   element: <CompleteSignUpPage />,
  // },
  // {
  //   path: RoutesEnum.RULES,
  //   element: <Rules />,
  // },
  // {
  //   path: RoutesEnum.HOWTOPLAY,
  //   element: <HowToPlay />,
  // },
  {
    path: RoutesEnum.REGISTRATION,
    element: <SignupPage />,
  },
  // {
  //   path: RoutesEnum.STEP2,
  //   element: <AccessFormStep2 />,
  // },
  // {
  //   path: RoutesEnum.STEP3,
  //   element: <AccessFormStep3 />,
  // },
];
