export enum RoutesEnum {
  SIGNIN = '/signin',
  LOGIN = '/login',
  HOME = '/',
  RESET = '/reset',
  FORGOT = '/forgot',
  RULES = '/rules',
  COMPLETE = '/complete',
  SIGNUP = '/signup',
  REGISTRATION = '/registration',
  STEP2 = '/step2',
  STEP3 = '/step3',
  UNSUB = '/unsubscribe',
  GAMEZONE = '/gamezone',
  PROFILE = '/profile',
  HOWTOPLAY = '/howtoplay',
  PRIZES = '/prizes',
  SSOENTRY = '/sso/entry',
}
