import { FC, useMemo } from 'react';

import {
  Flex,
  Image,
  useTheme,
  useMediaQuery,
  Icon,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/arrow-left.svg';
import HeaderImg from '@/assets/images/header.png';
import MobileHeaderImg from '@/assets/images/pif-atp-rankings-predictor-logo.png';

import Navigations from '@/components/Navigations';

import { useSidebarContext } from '@/context/sidebar.context';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';

import { GAME_REDIRECT_URL, IS_HEADER_HIDDEN } from '@/utils/constants';

const Header: FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { onSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();

  const isHeaderHide = useMemo(
    () =>
      [
        RoutesEnum.SSOENTRY,
        RoutesEnum.FORGOT,
        RoutesEnum.LOGIN,
        RoutesEnum.REGISTRATION,
        RoutesEnum.HOME,
        RoutesEnum.RESET,
        RoutesEnum.UNSUB,
        RoutesEnum.PROFILE,
      ].includes(pathname as RoutesEnum),
    [pathname],
  );

  if (IS_HEADER_HIDDEN || isHeaderHide) return null;

  return isDesktop ? (
    <Flex
      align='center'
      as='header'
      background={theme.colors.header.primaryBg}
      direction='column'
      h='100%'
      justify='center'
      w='100%'
    >
      <Image
        alt='header'
        maxH='218px'
        objectFit='cover'
        src={HeaderImg}
        w='100%'
      />
      <Navigations />
    </Flex>
  ) : (
    <Grid
      alignItems='center'
      as='header'
      bg='#121FFFAA'
      h='96px'
      templateColumns='1fr auto 1fr'
    >
      <GridItem pl='16px'>
        <NavLink to={`${GAME_REDIRECT_URL}/home`}>
          <Icon as={ArrowLeftIcon} display='block' h='40px' w='40px' />
        </NavLink>
      </GridItem>
      <GridItem colStart={2}>
        <Image maxW='178px' src={MobileHeaderImg} />
      </GridItem>
    </Grid>
  );
};

export default Header;
