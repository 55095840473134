import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enum/apiTags.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import {
  API_BASE_URL,
  CLIENT_CODE,
  GAMEZONE_TILES_URL,
  TENANT_CODE,
} from '@/utils/constants';
import { getStorageItem } from '@/utils/localStorage';

import {
  GetGameZoneContestRes,
  GetGameZoneTemplateRes,
  GetUserProfileRes,
  LoginUserBody,
  LoginWithTokenRes,
  ResetPasswordBody,
  ResetPasswordRes,
  SignInRes,
  SignUpRes,
  UpdateUserDataSubscriptionBody,
  UpdateUserProfileBody,
  UserRegisterBody,
} from '@/types/domain';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    getGameZoneTemplate: builder.query<GetGameZoneTemplateRes, void>({
      query: () =>
        `${GAMEZONE_TILES_URL}/clients/${CLIENT_CODE}/gamezone-template?tenant=${TENANT_CODE}`,
      providesTags: [ApiTagsEnum.GET_GAME_ZONE_TEMPLATE],
    }),
    signIn: builder.mutation<SignInRes, LoginUserBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/login`,
        body,
        method: 'POST',
      }),
    }),
    signUp: builder.mutation<SignUpRes, UserRegisterBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/signup`,
        body,
        method: 'POST',
        headers: { 'X-TENANT-ID': TENANT_CODE },
      }),
    }),
    generateTmpToken: builder.mutation<{ token: string }, void>({
      query: () => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/auth/tmp-token`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
    }),
    loginWithToken: builder.mutation<LoginWithTokenRes, { token: string }>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/login-with-token`,
        method: 'POST',
        body,
      }),
    }),
    requestResetLink: builder.mutation<{ success: boolean }, { email: string }>(
      {
        query: (body) => ({
          url: `${API_BASE_URL}/auth/forgot-password`,
          method: 'POST',
          body,
          headers: { 'X-TENANT-ID': TENANT_CODE },
        }),
      },
    ),
    resetPassword: builder.mutation<ResetPasswordRes, ResetPasswordBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/renew-password`,
        method: 'POST',
        body,
        headers: { 'X-TENANT-ID': TENANT_CODE },
      }),
    }),
    updateUserDataSubscription: builder.mutation<
      { success: boolean },
      UpdateUserDataSubscriptionBody
    >({
      query: (body) => ({
        url: `${API_BASE_URL}/user/notification-preferences`,
        method: 'PUT',
        body,
        headers: { 'X-TENANT-ID': TENANT_CODE },
      }),
    }),
    getUserProfile: builder.query<GetUserProfileRes, void>({
      query: () => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/user/me`,
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
      providesTags: [ApiTagsEnum.GET_USER_PROFILE],
    }),
    updateUserProfile: builder.mutation<
      { success: boolean },
      Partial<UpdateUserProfileBody>
    >({
      query: (body) => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/user`,
          method: 'PUT',
          body,
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
      invalidatesTags: [ApiTagsEnum.GET_USER_PROFILE],
    }),
    getGameZoneContest: builder.query<GetGameZoneContestRes, { url: string }>({
      query: ({ url }) => ({ url }),
    }),
  }),
});

export const {
  useGetGameZoneTemplateQuery,
  useSignInMutation,
  useSignUpMutation,
  useGenerateTmpTokenMutation,
  useLoginWithTokenMutation,
  useRequestResetLinkMutation,
  useResetPasswordMutation,
  useUpdateUserDataSubscriptionMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLazyGetGameZoneContestQuery,
  useLazyGetUserProfileQuery,
} = api;
