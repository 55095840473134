import { ElementType, FC } from 'react';

import {
  Flex,
  Link,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useNavigations } from '@/hooks/useNavigations';

interface NavItemProps {
  title: string;
  icon?: ElementType;
  link?: string;
  links?: NavItemProps[];
}

const NavItem: FC<NavItemProps> = ({ title, icon, link }) => (
  <Link as={NavLink} key={title} to={link}>
    <Flex
      _hover={{ bg: 'rgba(255,255,255, 0.1)' }}
      align='center'
      borderRadius='4px'
      color='white'
      cursor='pointer'
      gap='16px'
      p='12px 40px'
    >
      {icon && <Icon as={icon} h='24px' w='24px' />}
      <Text
        fontSize='16px'
        fontWeight='700'
        lineHeight='1.4'
        transform='translateY(3px)'
      >
        {title}
      </Text>
    </Flex>
  </Link>
);

const DropdownNavItem: FC<NavItemProps> = ({ title, icon, links }) => {
  const theme = useTheme();

  return (
    <Menu>
      <MenuButton>
        <Flex
          align='center'
          bg={theme.colors.button.primary.bg}
          borderRadius='4px'
          color={theme.colors.header.primaryBg}
          cursor='pointer'
          gap='16px'
          p='12px 40px'
        >
          {icon && <Icon as={icon} h='24px' w='24px' />}
          <Text
            fontSize='16px'
            fontWeight='700'
            lineHeight='1.4'
            transform='translateY(3px)'
          >
            {title}
          </Text>
        </Flex>
      </MenuButton>

      <MenuList p='0'>
        {links?.map((nav, idx) => (
          <MenuItem justifyContent='center' key={idx} p='16px' w='100%'>
            <NavLink to={nav.link || '#'}>
              {({ isActive = false }) => (
                <Text
                  fontSize='16px'
                  fontWeight={isActive ? '700' : '400'}
                  lineHeight='1.4'
                >
                  {nav.title}
                </Text>
              )}
            </NavLink>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const Navigations: FC = () => {
  const navigations = useNavigations();

  return (
    <Flex gap='4px' justify='center' py='8px'>
      {navigations.map((nav) =>
        nav?.links ? (
          <DropdownNavItem key={nav.title} {...nav} />
        ) : (
          <NavItem key={nav.title} {...nav} />
        ),
      )}
    </Flex>
  );
};

export default Navigations;
