import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'white',
  },
  sizes: {
    md: {
      control: {
        width: '24px',
        height: '24px',
      },
      label: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '133%',
        marginLeft: '12px',
      },
    },
  },
  baseStyle: {
    control: {
      borderColor: 'checkbox.control',
      borderRadius: '4px',

      _checked: {
        background: 'checkbox.control',
        color: 'checkbox.icon',
        border: 'none',
      },
    },
  },
};

export default Checkbox;
