import { ComponentStyleConfig } from '@chakra-ui/react';

const Mark: ComponentStyleConfig = {
  baseStyle: {
    whiteSpace: 'normal',
    color: 'inherit',
  },
};

export default Mark;
