import { ReactComponent as HelpIcon } from '@/assets/icons/circle-help-outline.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/home.svg';
import { ReactComponent as LeaderboardIcon } from '@/assets/icons/leaderboard-outline.svg';
import { ReactComponent as TennisBallIcon } from '@/assets/icons/tennis-ball.svg';
import { ReactComponent as TennisRacketIcon } from '@/assets/icons/tennis-racket.svg';

import { RoutesEnum } from '@/enum/routes.enum';

import { GAME_REDIRECT_URL } from '@/utils/constants';

const NAVIGATIONS = [
  { icon: HomeIcon, title: 'Home', link: `${GAME_REDIRECT_URL}/home` },
  { icon: TennisRacketIcon, title: 'Play', link: `${GAME_REDIRECT_URL}/home` },
  {
    icon: LeaderboardIcon,
    title: 'Leaderboards',
    link: `${GAME_REDIRECT_URL}/monthly`,
  },
  {
    icon: TennisBallIcon,
    title: 'Schedule',
    link: `${GAME_REDIRECT_URL}/ranking`,
  },
  {
    icon: HelpIcon,
    title: 'Help',
    links: [
      { title: 'Profile', link: RoutesEnum.PROFILE },
      { title: 'Official Rules', link: `${GAME_REDIRECT_URL}/rules` },
      { title: 'How to play', link: `${GAME_REDIRECT_URL}/how-to-play` },
      { title: 'Prizes', link: `${GAME_REDIRECT_URL}/prize` },
    ],
  },
];

export const useNavigations = () => {
  return NAVIGATIONS;
};
